
<template>
  <div class="home">
    <van-nav-bar title="话单" left-arrow id="reset" left-text="返回" @click-left="onClickLeft"/>
  

<van-swipe-cell  v-for="(item,index) in list" :key="index" >
 <div class="items" >
      <img src="../static/消息/gonggao.png" alt="" width="50" height="50">
      <div class="txt">
        <div class="t1">
          <p class="tname">号码：{{item.calledNumber}}</p>
          <p class="tc">时长：{{item.shichang}}分钟 计费：{{item.jifei}}</p>
        </div>
<!--        <div class="t2">
          <p class="tc"> 关于5.1假期旅游征...</p>

        </div> -->
      </div>
    </div>
 <!-- <template #right> -->
    <!-- <van-button square type="danger" text="提交"   @click="login(item.calledNumber)" class="delete-button"/> -->
    <!-- <van-button square type="primary" text="收藏"  class="delete-button"/> -->
  <!-- </template> -->
</van-swipe-cell>

<br /><br /><br />




  


  </div>
</template>

<script>
	import { Message } from 'iview'
	import moment from 'moment';

export default {
  data() {
    return {
      active: 0,
      name:'',
	  list:{},
	  
    };
  },created () {
    this.getHistoryData()
  },
methods:{
	routeTo(customer_id)
	{
 		console.log(customer_id);
		this.$router.push({
		  path: `/kehuxiangqing?id=`+customer_id,
		})
		
	},
	dateFormat(row, column) {
      var date = row[column.property];
      if (date == undefined) {
        return "";
      }
      return moment(date).format("YYYY-MM-DD");
    },
 
    onClickLeft() {
    this.$router.go(-1);//返回上一层
    },
 
   login(tname){
 		let param = new URLSearchParams();
 		
 		 param.append("name", tname);
 	 param.append("token", this.$store.getters. getDemoValue);
 		
 		this.axios.post('/vue.php?m=index&a=wangyi',param).then(res => {
			
			
				// Message.info(res.data.msg);
 			if (res.data.data.code === 200) {
 				Message.info('提交成功');
 			}
 		
 			console.log(res);
 		}).catch(function (error){
 			Message.info('error');
 			console.log(error);
 		});
 		
     // this.$router.push({
     //   path:"/crm"
     // })
   },
 getHistoryData(){
 	let param = new URLSearchParams();
 	
 	param.append("by", 'customer');
 	 param.append("token", this.$store.getters. getDemoValue);
 	
 	this.axios.post('/vue.php?m=index&a=dynamic1',param).then(res => {
 		if (res.data.status === -1) {

 			setTimeout(()=>{
 				this.$router.push({
 				  path:"/"
 				})
 			},1000)
 		}
 		if (res.data.status === 1) {
 			
			this.list = res.data.list;
 			console.log(this.list);
 		
 		}
 		console.log(res);
 	}).catch(function (error){
 		console.log(error);
 	});
 }
 
},

};
</script>

<style lang="scss" scoped>
#reset ::v-deep .van-nav-bar__title {
  color: #ffffff !important;
}
.van-nav-bar {
  background: #1288f4;
}
#reset ::v-deep .van-icon.van-icon-arrow-left.van-nav-bar__arrow {
  color: white !important;
}
.home {
  background: #f2f2f2;
  height: 100vh;
}
  .delete-button {
    height: 100%;
  }

.items {
  // width: 355px;
  height: 75px;
  background: #ffffff;
  border-radius: 5px;
  margin: 10px auto;
  display: flex;
  align-items: center;
  img {
    margin-left: 15px;
  }
  .img {
    width: 50px;
    height: 50px;
    background: #eca756;
    border-radius: 50%;
    margin-left: 15px;
  }
  .img1 {
    width: 50px;
    height: 50px;
    background: #7cd364;
    border-radius: 50%;
    margin-left: 15px;
  }
}
.txt {
  margin-left: 10px;
  width: 75%;
  display: flex;
  flex-wrap: wrap;
  display: flex;
  // justify-content: center;
  align-items: center;
  p {
    margin: 5px 0;
  }
  .tname {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
  }
  .tc {
    font-size: 11px;
    font-weight: 400;
    color: #cccccc;
  }
  .t1 {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
</style>